var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{attrs:{"pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"8","md":"6","lg":"4","xl":"3"}},[(!_vm.useCompanyName)?_c('span',[_vm._v("Persönliches / privates Konto")]):_vm._e(),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.useCompanyName),expression:"useCompanyName"}],attrs:{"label":"Firmenname","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading || !_vm.useCompanyName,"rules":_vm.companyNameRule},on:{"blur":() => {
							_vm.customer.companyName = _vm.customer.companyName.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.companyName),callback:function ($$v) {_vm.$set(_vm.customer, "companyName", $$v)},expression:"customer.companyName"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.useCompanyName),expression:"useCompanyName"}],attrs:{"label":"MwSt-Nummer / Umsatzsteuer-Id","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading || !_vm.useCompanyName,"rules":_vm.customerMwstNumberRule},on:{"blur":() => {
							_vm.customer.customerMwstNumber = _vm.customer.customerMwstNumber.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.customerMwstNumber),callback:function ($$v) {_vm.$set(_vm.customer, "customerMwstNumber", $$v)},expression:"customer.customerMwstNumber"}}),_c('v-checkbox',{attrs:{"disabled":!_vm.canUserChangeSettings || _vm.isLoading,"label":"Als Firmenkonto verwenden","color":"accent"},on:{"change":() => {
							this.customer.companyName = _vm.useCompanyName ? '' : 'privat';
							if (!_vm.useCompanyName) this.customer.customerMwstNumber = '';
							_vm.saveCustomer();
							_vm.validate();
						}},model:{value:(_vm.useCompanyName),callback:function ($$v) {_vm.useCompanyName=$$v},expression:"useCompanyName"}})],1)],1),_c('v-row',{attrs:{"pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Strasse / Nr.","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.addressRequiredStringMax35Rule},on:{"blur":() => {
							_vm.customer.street = _vm.customer.street.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.street),callback:function ($$v) {_vm.$set(_vm.customer, "street", $$v)},expression:"customer.street"}})],1)],1),_c('v-row',{attrs:{"pa-0":"","ma-0":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2","xl":"1"}},[_c('v-text-field',{attrs:{"label":"PLZ","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.correctPlzRule},on:{"blur":() => {
							_vm.customer.plz = _vm.customer.plz.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.plz),callback:function ($$v) {_vm.$set(_vm.customer, "plz", $$v)},expression:"customer.plz"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"label":"Ort","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.addressRequiredStringMax35Rule},on:{"blur":() => {
							_vm.customer.town = _vm.customer.town.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.town),callback:function ($$v) {_vm.$set(_vm.customer, "town", $$v)},expression:"customer.town"}})],1)],1),_c('v-row',{attrs:{"pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Land","color":"accent","disabled":true},model:{value:(_vm.customer.country),callback:function ($$v) {_vm.$set(_vm.customer, "country", $$v)},expression:"customer.country"}})],1)],1),_c('v-row',{attrs:{"pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Telefon (allgemein)","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.requiredStringMax35Rule},on:{"blur":() => {
							_vm.customer.generalPhone = _vm.customer.generalPhone.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.generalPhone),callback:function ($$v) {_vm.$set(_vm.customer, "generalPhone", $$v)},expression:"customer.generalPhone"}})],1)],1),_c('v-row',{attrs:{"row":"","pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"label":"E-Mail (allgemein)","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.requiredEmailRule},on:{"blur":() => {
							_vm.customer.generalEmail = _vm.customer.generalEmail.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.generalEmail),callback:function ($$v) {_vm.$set(_vm.customer, "generalEmail", $$v)},expression:"customer.generalEmail"}})],1)],1),_c('v-row',{attrs:{"row":"","pa-0":"","ma-0":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"label":"E-Mail für zusätzliche Auftragsbenachrichtigungen","color":"accent","disabled":!_vm.canUserChangeSettings || _vm.isLoading,"rules":_vm.optionalEmailRule},on:{"blur":() => {
							_vm.customer.defaultAdditionalOrderConfirmationEmail = _vm.customer.defaultAdditionalOrderConfirmationEmail.trim();
							_vm.saveCustomer();
						}},model:{value:(_vm.customer.defaultAdditionalOrderConfirmationEmail),callback:function ($$v) {_vm.$set(_vm.customer, "defaultAdditionalOrderConfirmationEmail", $$v)},expression:"customer.defaultAdditionalOrderConfirmationEmail"}}),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v("Wenn hier eine E-Mail Adresse eingetragen ist, wird diese standardmässig als zusätzliche E-Mail Adresse für Auftragsbenachrichtigungen verwendet.")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }